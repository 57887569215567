<template>
  <section class="terms">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>VERTEX GMBH</h2>
          <p>
            Bogenstr. 15 <br>
            47799 Krefeld <br>
            Deutschland / Germany
          </p>
          <p>
            Managing Director: <br>
            Alexander Lanzow <br>
            Tel.: +49 2151 – 653 70 77
          </p>
          <p>
            Legal information <br>
            Sales tax identification number according to §27a sales tax law: DE329157564 <br>
            Register court: Amsgericht Krefeld
          </p>
          <p>
            Responsible for content according to TMG <br>
            KRAFT Industries GmbH
          </p>
          <div id="icon_links">
            <p>
              Font generated by <a href="https://www.flaticon.com">flaticon.com</a>. Under <a href="http://creativecommons.org/licenses/by/3.0/">CC</a>: <a data-file="028-team" href="http://www.freepik.com">Freepik</a>, <a data-file="006-connection" href="https://www.flaticon.com/authors/ddara">dDara</a>, <a data-file="027-feedback" href="https://www.flaticon.com/authors/eucalyp">Eucalyp</a>, <a data-file="020-engineer" href="https://www.flaticon.com/authors/phatplus">phatplus</a>, <a data-file="010-warehouse" href="https://www.flaticon.com/authors/monkik">monkik</a>, <a data-file="015-manufacture" href="https://www.flaticon.com/authors/xnimrodx">xnimrodx</a>, <a data-file="016-cogwheel" href="https://www.flaticon.com/authors/pause08">Pause08</a>, <a data-file="026-thinking" href="https://www.flaticon.com/authors/smashicons">Smashicons</a>, <a data-file="025-checklist" href="https://www.flaticon.com/authors/smalllikeart">smalllikeart</a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import GeneralSecond from '@/components/GeneralSecond'
export default {
  name: 'Imprint',
  components: {
    GeneralSecond
  },
  data () {
    return {
      page: {
        pageName: 'Imprint',
        description: ''
      }
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length > 0) {
      location.href = `${location.href}#${this.$route.params.id}`
    }
  },
  metaInfo: {
    title: 'Imprint',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
