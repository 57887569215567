<template>
  <section id="subtitle-block">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="8" lg="6">
          <p class="pre-title">VERTEX GmbH</p>
          <div class="orange-line">
            <div class="line"></div>
          </div>
          <h1>{{ name }}</h1>
        </v-col>
        <v-col cols="12">
          <p v-if="descript.length > 0" class="subtitle-decript">
            {{ descript }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  props: ['name', 'descript']
}
</script>

<style scoped>
#subtitle-block {
  background:
    url(../assets/Scrim.jpg);
}
</style>
